import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

import Header from './page/Header';
import Content from './page/Content';
import Footer from './page/Footer';

function App() {
  return (
    <div className="app">
      <CssBaseline/>
      <Header/>
      <Content/>
      <Footer/>
    </div>
  );
}

export default App;
