import React from 'react';

function Content() {
  return (
    <div>
        this page is currently under development
    </div>
  );
}

export default Content;
